import React from "react";
import { Modal } from "react-bootstrap";
import Button from "src/components/Button/Button";

interface IConfirmLocationChangeProps {
  showModal: boolean;
  loading: boolean;
  handleClose: () => void;
  confirmChange: () => void;
  isDeliveryOrderModal?: boolean;
}
const ConfirmLocationChangeModal: React.FC<IConfirmLocationChangeProps> = ({
  showModal,
  handleClose,
  confirmChange,
  loading,
  isDeliveryOrderModal = false
}) => {
  return (
    <>
      <Modal
        show={showModal}
        onHide={handleClose}
        centered
        className="theme-modal-style app_container_wrap no-padding border-radius-8 no-border"
      >
        <Modal.Header className="pt-0" id="close_button2">
          <button
            type="button"
            className="btn modal-close-button pe-0 blue-icon"
            onClick={handleClose}
          >
            Close
          </button>
        </Modal.Header>
        <Modal.Body className="text-center py-5">
          <h6 className="font-Cls ls-normal mb-sm-4 mb-3 f-s22">
            {isDeliveryOrderModal ? "Change Address" : "Change Location"}
          </h6>
          <p className="f-s14 mb-4">
            Are you sure you want to change<br />
            your { isDeliveryOrderModal ? "Address?" : "location?" }
            Your selection will be reset.
          </p>
          <div className="text-center">
            <Button
              type="button"
              onClick={confirmChange}
              disabled={loading}
            >
              Continue
            </Button>
            <button
              type="button"
              onClick={handleClose}
              className="btn p-0 f-w6 clr-red font-Vcf mt-3"
            >
              No, Keep the Location
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ConfirmLocationChangeModal;
