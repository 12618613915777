import React from 'react';
import Bugsnag, { BreadcrumbType } from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

import { APP_VERSION } from '../constants';

import { getUser } from './helperMethods';

const BugSnagTypes = { STATE: 'state', ERROR: 'error' };

export const bugSnagInititalize = () => {
  const isEnabledReleaseStage = Boolean(
    import.meta.env.VITE_REACT_APP_BUG_SNAG_IS_ENABLED_RELEASE_STAGE,
  );
  const enabledReleaseStages = isEnabledReleaseStage
    ? [import.meta.env.VITE_REACT_APP_ENV]
    : [];

  Bugsnag.start({
    apiKey: isEnabledReleaseStage
      ? import.meta.env.VITE_REACT_APP_BUG_SNAG_API_KEY
      : 'noAPIKeyNeeded',
    plugins: [new BugsnagPluginReact()],
    onError: (event) => {
      const user = getUser();
      event.setUser(user.id, user.email, user.full_name);
      const errorClass = event?.errors[0]?.errorClass;
      const errorMessage = event?.errors[0]?.errorMessage;
      const originalError = event?.originalError;

      if (originalError) {
        // Check if the stack trace contains googleapis.com/maps/api
        if (originalError.stack && originalError.stack.includes("googleapis.com/maps/api")) {
          console.warn("Google Maps API error ignored:", originalError.stack);
          return false; // Prevent Bugsnag from reporting this error
        }
      }
      // Check for the chunk error class or message
      if (
        errorClass === 'ChunkLoadError' ||
        errorMessage.includes('Loading CSS chunk') ||
        errorMessage?.includes("Failed to load Stripe.js") ||
        errorMessage?.includes("Network Error") ||
        errorMessage.includes(".scrollHeight") ||
        errorMessage?.includes("Could not load \"util\"") ||
        errorMessage?.includes("Unable to preload CSS")
      ) {
        return false; // Prevent Bugsnag from reporting this error
      }

      if (
        errorMessage?.includes("Failed to fetch dynamically imported") || 
        errorMessage?.includes("unhandledrejection handler") 
      )
      {
        Bugsnag.notify(new Error("Non-error promise rejection"), (bugsnagEvent) => {
          bugsnagEvent.addMetadata("promiseRejection", {
            reason: JSON.stringify(event),
            type: typeof event
          });
        });
        return false;
      }
    },
    enabledReleaseStages,
    releaseStage: import.meta.env.VITE_REACT_APP_ENV,
    appVersion: APP_VERSION,
  });
  return Bugsnag.getPlugin('react').createErrorBoundary(React);
};

export const bugSnagNotifyAPIError = (
  apiName: string,
  requestPayload: object,
  responseErrorMessage: string,
) => {
  Bugsnag.leaveBreadcrumb(
    `Failed API: ${apiName}`,
    requestPayload,
    BugSnagTypes.ERROR as BreadcrumbType,
  );
  Bugsnag.notify(responseErrorMessage);
};
