import React, { useEffect, useRef, useState } from "react";
import { getUser, isGuestUser, setUser } from "src/helper/helperMethods";
import { useAppDispatch } from "src/redux/store/store";
import { updateUserData } from "../redux/slices";
import { logout } from "../redux/actions";
import useRefreshAuthToken from "src/react-query-hooks/Authentication/useRefreshAuthToken";
import useUtcTimeZone from "src/react-query-hooks/Authentication/useGetUtcTime";
import Loader from "src/components/Loader/Loader";
import _debounce from "lodash/debounce";

const Authentication = ({ children }) => {
    const [isRefreshing, setIsRefreshing] = useState<boolean>(true); //Delays Rendering untill the token is refreshed.
    let { mutateAsync: refreshAuthToken } = useRefreshAuthToken();
    const dispatch = useAppDispatch();
    const { mutateAsync: currentUtcTime } = useUtcTimeZone();
    // Sometime refresh token call trigers twice due to re-rendering
    // So Adding useRef to manage state, if there is already a call in progress for refresh token
    const isFetchingToken = useRef(false);

    const refreshToken = async () => {
        if (isFetchingToken.current) return;
        const user = getUser();
        if (isGuestUser(user) || !user?.authentication_token) {
            setIsRefreshing(false);
            return;
        }
        const authExpiresAt = user.expires_at || null;
        const refreshExpiresAt = user.refresh_expires_at || null;
        const authTokenExpiry = new Date(authExpiresAt).getTime();
        const utcNow = await currentUtcTime();
        const currentDate = new Date(utcNow).getTime();
        const refreshTokenExpiry = new Date(refreshExpiresAt).getTime();
        const remainingAuthTokenHours = (authTokenExpiry - currentDate) / 3600000; //converts remaining time to hours
        const remainingRefreshTokenHours = (refreshTokenExpiry - currentDate) / 3600000; //converts remaining time to hours
        const shouldLogout = authExpiresAt && remainingAuthTokenHours <= 0 && remainingRefreshTokenHours <= 0;
        const shouldRefetchToken = remainingAuthTokenHours <= 24 || remainingRefreshTokenHours <= 24 || !authExpiresAt;
        
        console.log("shouldLogout ---------- ", shouldLogout);
        console.log("shouldRefetchToken ------------ ", shouldRefetchToken);

        if (shouldLogout) {
            logout(dispatch);
            return;
        }
        if (shouldRefetchToken) {
            isFetchingToken.current = true;
            setIsRefreshing(true);
            try {
                const response = await refreshAuthToken({
                    refresh_token: user.refresh_token,
                    auth_token: user.authentication_token,
                });
                setUser(response);
                dispatch(updateUserData(response));
            }
            catch (error) {
                isFetchingToken.current = false;
                setIsRefreshing(false);
                console.log("Refresh token failed.");
                logout(dispatch);
            }
        }
        isFetchingToken.current = false;
        setIsRefreshing(false);
    }
    //Adding debounce on refresh token for delaying multiple refresh token calls
    const debouncedRefreshToken = _debounce(refreshToken, 1500);
    useEffect(() => {
        // const handleVisibilityChange = () => {
        //     if (!document.hidden) {
        //         debouncedRefreshToken();
        //     }
        // };
        refreshToken();
        // Adding window listener temporarily for focus for current running sessions which do not have expiry tokens
        // or refresh tokens in their local storage yet
        // in case users have tabs open for a while
        // document.addEventListener("visibilitychange", handleVisibilityChange);
        // return () => {
        //     document.removeEventListener("visibilitychange", handleVisibilityChange);
        // };
    }, []);

    return (
        <>
            {!isRefreshing ? children :
                <Loader />
            }
        </>
    );
}
export default Authentication;
